import { BrowserRouter, Route ,Routes } from 'react-router-dom'; // React Router setup
import Home from "./Comonents/Pages/Home";
import Footer from "./Comonents/Utils/Footer";
import Navbar from "./Comonents/Utils/Navbar";
import Newsentry from "./Comonents/Admin/Pages/Newsentry";

function App() {
  return (
   
    <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/newsentry' element={<Newsentry/>}/>
        </Routes>
    </BrowserRouter>


    // <div className="App">



    //  <Navbar/>
    //  <Home/>
    //  <Footer/>



    //  {/* admin */}
    //  <Newsentry/>
     
    // </div>
  );
}

export default App;
