import React, { useState } from 'react'
import logo from '../Assets/logo.png'
import '../Css/Navbar.css'
import { Link } from 'react-router-dom';

export default function Navbar() {

  // State to hold the search query
  const [query, setQuery] = useState("");

  // Function to handle form submission
  const handleSearch = (event) => {
    event.preventDefault(); // Prevent page reload
    // Redirect to Google search with the query
    if (query) {
      window.location.href = `https://www.google.com/search?q=${query}`;
    }
  };


  // resnavbar
  const [isNavVisible, setIsNavVisible] = useState(false);

  const handleCheckboxChange = () => {
    setIsNavVisible(prevState => !prevState);
  };


  return (
    <header>
      <div className="main-container">
        <div className="uper-nav">
          <div className="uper-left">
          <a href="https://www.iitrpr.ac.in/logo-iit-ropar" target="_blank" style={{display:"flex", alignItems:"center"}}> <div className="logo">
              <img src={logo} alt="" />
            </div>
            <div className="web-heading">
              <h4>ਭਾਰਤੀ ਤਕਨਾਲੋਜੀ ਸੰਸਥਾ ਰੋਪੜ</h4>
              <h4>भारतीय प्रौद्योगिकी संस्थान रोपड़</h4>
              <h4>Indian Institute of Technology Ropar</h4>
            </div></a>
          </div>

          <div className="uper-right">
            <ul>
              <div className='li'>
              <a href="https://cga.iitrpr.ac.in/?source=opt1" target="_blank"><li>Donate to IIT Ropar</li></a>
              <a href="http://intranet.iitrpr.ac.in/" target="_blank"><li>Intranet</li></a>
              <a href="https://www.iitrpr.ac.in/user/login" target="_blank"><li>Login</li></a>
              <a href="https://mail.google.com/mail/u/0/" target="_blank"><li>Webmail</li></a>
              <a href="https://www.iitrpr.ac.in/hi" target="_blank"><li>हिन्दी</li></a>
              </div>
              <div className='form'>
              <form onSubmit={handleSearch}>
                <input type="text" value={query} className='g-search' onChange={(e) => setQuery(e.target.value)} />
                <button className='g-btn' type="submit" style={{cursor:"pointer"}}>Search</button>
              </form>
              </div>
            </ul>
          </div>
        </div>

        <div className="lower-nav">
          <ul>
            <Link to="/"><li>Home</li></Link>
            <a href="https://www.iitrpr.ac.in/about-iit-ropar" target="_blank" className='multi-items'><li>
              About Us
              <div className="more-items">
                <a href="https://www.iitrpr.ac.in/vision-and-mission" target="_blank"><li>Vision and Mission</li></a>
                <a href="https://www.iitrpr.ac.in/sites/default/files/Statutes-335-400%20-%20IIT%20ROPAR.pdf" target="_blank"><li>Institute Statutes</li></a>
                <a href="https://www.iitrpr.ac.in/sites/default/files/IIT_ACT_2012.pdf" target="_blank"><li>Act</li></a>
                <a href="https://www.iitrpr.ac.in/board-governors" target="_blank"><li>Board of Governors(BOG)</li></a>
                <a href="https://www.iitrpr.ac.in/director" target="_blank"><li>Director</li></a>
                <a href="https://www.iitrpr.ac.in/finance-committee" target="_blank"><li>Finance Committee</li></a>
                <a href="https://www.iitrpr.ac.in/building-works-committee" target="_blank"><li>Building Works Committee</li></a>
                <a href="https://www.iitrpr.ac.in/senate" target="_blank"><li>Senate</li></a>
                <a href="https://www.iitrpr.ac.in/iitropar-administration" target="_blank"><li>IIT Ropar Administration</li></a>
                <a href="https://www.iitrpr.ac.in/administrative-officers" target="_blank"><li>Administrative Officers</li></a>
                <a href="https://www.iitrpr.ac.in/organisation-structure" target="_blank"><li>Organisation Structure</li></a>
              </div>
            </li></a>

            <a href="https://www.iitrpr.ac.in/admissions" target="_blank"><li>Admissions</li></a>
            <a href="https://www.iitrpr.ac.in/departments-centers" target="_blank" className='multi-items'><li>
              Departments
              <div className="more-items">
                <a href="https://www.iitrpr.ac.in/saide/" target="_blank"><li>Artificial Intelligene and Data Engineering</li></a>
                <a href="https://www.iitrpr.ac.in/cbme/" target="_blank"><li>BioMedical</li></a>
                <a href="https://www.iitrpr.ac.in/chemical/Index/index.php" target="_blank"><li>Chemical</li></a>
                <a href="https://www.iitrpr.ac.in/chemistry/" target="_blank"><li>Chemistry</li></a>
                <a href="https://www.iitrpr.ac.in/civil/" target="_blank"><li>Civil</li></a>
                <a href="https://cse.iitrpr.ac.in/" target="_blank"><li>CSE</li></a>
                <a href="https://www.iitrpr.ac.in/ee/" target="_blank"><li>Electrical</li></a>
                <a href="https://www.iitrpr.ac.in/hss/" target="_blank"><li>HSS</li></a>
                <a href="https://www.iitrpr.ac.in/math/" target="_blank"><li>Mathamatics</li></a>
                <a href="https://mech.iitrpr.ac.in/" target="_blank"><li>Mechanical</li></a>
                <a href="https://mme.iitrpr.ac.in/" target="_blank"><li>Metallurgical and Materials Engineering</li></a>
                <a href="https://www.iitrpr.ac.in/physics/" target="_blank"><li>Physics</li></a>
              </div>
            </li></a>
            <a href="https://www.iitrpr.ac.in/center" target="_blank" className='multi-items'><li>
              Centers
              <div className="more-items">
                <a href="https://www.iitrpr.ac.in/datascience/" target="_blank"><li>CARDS</li></a>
                <a href="https://www.iitrpr.ac.in/coe-sards/" target="_blank"><li>CoE-SARDS</li></a>
                <a href="https://www.iitrpr.ac.in/center-engineering-education" target="_blank"><li>CEE</li></a>
                <a href="https://www.iitrpr.ac.in/indo-taiwan/" target="_blank"><li>IT JRC</li></a>
                <a href="https://iitrpr.ac.in/creed" target="_blank"><li>CREED</li></a>
              </div>
            </li></a>
            <a href="https://www.iitrpr.ac.in/iitrpr-academics" target="_blank"><li>Academics</li></a>
            <a href="https://www.iitrpr.ac.in/research-iit-ropar" target="_blank"><li>Research</li></a>
            <a href="https://www.iitrpr.ac.in/students" target="_blank"><li>Students</li></a>

            <a href="https://www.iitrpr.ac.in/facilities" target="_blank" className='multi-items'><li>
              Facilities
              <div className="more-items">
                <a href="https://www.iitrpr.ac.in/library/" target="_blank"><li>Library</li></a>
                <a href="https://www.iitrpr.ac.in/medical-center/" target="_blank"><li>Medical Center</li></a>
                <a href="https://snehita-well-being.vercel.app/" target="_blank"><li>Snehita Well Being</li></a>
                <a href="https://www.iitrpr.ac.in/hostels" target="_blank"><li>Hostel</li></a>
                {/* <a href="https://www.iitrpr.ac.in/bosa/#/home" target="_blank"><li>Sports</li></a> */}
                <a href="https://www.iitrpr.ac.in/guest-house" target="_blank"><li>Guest House</li></a>
                <a href="https://www.iitrpr.ac.in/institute-bus-facility" target="_blank"><li>Transport Facilities</li></a>
                <a href="https://www.iitrpr.ac.in/nkn" target="_blank"><li>NKN</li></a>
                <a href="https://sites.google.com/iitrpr.ac.in/creche/home" target="_blank"><li>Day Care Facility</li></a>
                <a href="https://www.iitrpr.ac.in/downloads/index.html" target="_blank"><li>Download Forms</li></a>
              </div>
            </li></a>
            <a href="https://www.iitrpr.ac.in/oir/" target="_blank"><li>International</li></a>
            <a href="https://www.iitrpr.ac.in/job@iitropar" target="_blank"><li>Jobs</li></a>
          </ul>
        </div>


        {/* ----responsive-nav */}
        <div className="res-div-btn" >
          <label className="res-btn" htmlFor="navigationbtn">Navigation</label>
          <input type="checkbox" id="navigationbtn" onChange={handleCheckboxChange}/>
        </div>

        <div className="res-lower-nav" style={{ display: isNavVisible ? 'block' : 'none' }}>
          <ul>
            <a href=""><li>Home</li></a>
            <a href="https://www.iitrpr.ac.in/about-iit-ropar" target="_blank" className='res-multi-items'><li>
              About Us
              <div className="res-more-items">
                <a href=""><li>Vision and Mission</li></a>
                <a href=""><li>Institute Statutes</li></a>
                <a href=""><li>Act</li></a>
                <a href=""><li>Board of Governors(BOG)</li></a>
                <a href=""><li>Director</li></a>
                <a href=""><li>Finance Committee</li></a>
                <a href=""><li>Building Works Committee</li></a>
                <a href=""><li>Senate</li></a>
                <a href=""><li>IIT Ropar Administration</li></a>
                <a href=""><li>Administrative Officers</li></a>
                <a href=""><li>Organisation Structure</li></a>
              </div>
            </li></a>

            <a href="https://www.iitrpr.ac.in/admissions" target="_blank"><li>Admissions</li></a>
            <a href="https://www.iitrpr.ac.in/departments-centers" target="_blank" className='res-multi-items'><li>
              Departments
              <div className="res-more-items">
                <a href=""><li>Artificial Intelligene and Data Engineering</li></a>
                <a href=""><li>BioMedical</li></a>
                <a href=""><li>Chemical</li></a>
                <a href=""><li>Chemistry</li></a>
                <a href=""><li>Civil</li></a>
                <a href=""><li>CSE</li></a>
                <a href=""><li>Electrical</li></a>
                <a href=""><li>HSS</li></a>
                <a href=""><li>Mathamatics</li></a>
                <a href=""><li>Mechanical</li></a>
                <a href=""><li>Metallurgical and Materials Engineering</li></a>
                <a href=""><li>Physics</li></a>
              </div>
            </li></a>
            <a href="https://www.iitrpr.ac.in/center" target="_blank" className='res-multi-items'><li>
              Centers   
              <div className="res-more-items">
                <a href=""><li>CARDS</li></a>
                <a href=""><li>CoE-SARDS</li></a>
                <a href=""><li>CEE</li></a>
                <a href=""><li>IT JRC</li></a>
                <a href=""><li>CREED</li></a>
              </div>
            </li></a>
            <a href="https://www.iitrpr.ac.in/iitrpr-academics" target="_blank"><li>Academics</li></a>
            <a href="https://www.iitrpr.ac.in/research-iit-ropar" target="_blank"><li>Research</li></a>
            <a href="https://www.iitrpr.ac.in/students" target="_blank"><li>Students</li></a>
            <a href="https://www.iitrpr.ac.in/facilities" target="_blank" className='res-multi-items'><li>
              Facilities
              <div className="res-more-items">
                <a href=""><li>Library</li></a>
                <a href=""><li>Medical Center</li></a>
                <a href=""><li>Snehita Well Being</li></a>
                <a href=""><li>Hostel</li></a>
                <a href=""><li>Sports</li></a>
                <a href=""><li>Guest House</li></a>
                <a href=""><li>Transport Facilities</li></a>
                <a href=""><li>NKN</li></a>
                <a href=""><li>Day Care Facility</li></a>
                <a href=""><li>Download Forms</li></a>
              </div>
            </li></a>
            <a href="https://www.iitrpr.ac.in/oir/" target="_blank"><li>International</li></a>
            <a href="https://www.iitrpr.ac.in/job@iitropar" target="_blank"><li>Jobs</li></a>
          </ul>
        </div>





      </div>
    </header>
  )
}
