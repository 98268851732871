import React, { useState } from "react";
import Navbar from "../../Utils/Navbar";
import Footer from "../../Utils/Footer";

export default function Newsentry() {
  
  const [news, setNews] = useState({
      title:""
  })
  
  const handleinput = (e)=>{

    console.log(e)
      let name = e.target.name;
      let value = e.target.value;
      
      setNews({
        ...news,
        [name]:value,
      });
       
  }

  const handlesubmit = async (e)=>{
    e.preventDefault();
    console.log(news)
    try{
    const response = await fetch(`http://localhost:5000/api/auth/news`, {
      method:"POST",
      headers:{
        'content-Type':'application/json',
      },
      body:JSON.stringify(news)
    })

    console.log(response)
    }catch(error){
      console.log(error, "restitrjtlkfjlskadj")
    }
  }
  
  return (
    <>
    <Navbar/>
      <div style={{border:"2px solid red", width:"400px", height:"400px", margin:"auto", display:'flex', alignItems:"center"}}>
        <form onSubmit={handlesubmit}>
          <label htmlFor="title"> title
            <input type="text" placeholder="Enter title" id="title"  name="title" value={news.title} onChange={handleinput}/>
          </label>
          <label htmlFor="attach">
            <input type="file" id="attachement" name="attachement" />
          </label>
          <button type="submit" >submit</button>
        </form>
      </div>
      <Footer/>
    </>
  );
}
