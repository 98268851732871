import React from "react";
import "../Css/Footer.css";

import facebook from "../Assets/facebook.png"
import twitter from "../Assets/twitter.png"
import instagram from "../Assets/instagram.png"
import location from "../Assets/locicon.png"
import { Link } from "react-router-dom";


export default function Footer() {
    return (
        <div className="footer">
            <div className="main-container">
                <div className="footer-section">

                    <div className="footer-section-content">
                        <h3 className="footer-heading">QUICK FINDS</h3>
                        <ul>
                            <a href="https://www.iitrpr.ac.in/annual-reports" target="_blank"><li>Annual Reports</li></a>
                            <a href="https://docs.google.com/document/d/1oFeyY-JxaXzPH0hWT1HTMEA_nOtyz1g1w2XYEwTC9_Y/edit?pli=1&tab=t.0" target="_blank"><li>Bus Timing</li></a>
                            <a href="https://sites.google.com/view/centralworkshopiitrpr/home?authuser=0" target="_blank"><li>Central Workshop</li></a>
                            <a href="https://www.iitrpr.ac.in/crf1" target="_blank"><li>Central Research Facilities</li></a>
                            <a href="http://172.30.8.23/Web/" target="_blank"><li>Booking Portal</li></a>
                            <a href="https://snehita-well-being.vercel.app/" target="_blank"><li>Counselling Cell</li></a>
                            <a href="https://www.iitrpr.ac.in/downloads/index.html" target="_blank"><li>Download Forms</li></a>
                            <a href="https://www.iitrpr.ac.in/sexual-harassment-complaints-committee" target="_blank"><li>Internal Complaintes Committee(ICC)</li></a>
                            <a href="https://www.iitrpr.ac.in/sites/default/files/Audit%20Manual%20Final%20Copy%20%20%28%20Revised%29.pdf" target="_blank"><li>Internal Audit manual</li></a>
                            <a href="https://www.iitrpr.ac.in/EOC" target="_blank"><li>Equal Opporunity Cell (EOC)</li></a>
                            <a href="https://www.iitrpr.ac.in/sops-sections" target="_blank"><li>SoPs of various sections</li></a>
                            <a href="https://www.iitrpr.ac.in/list-holidays" target="_blank"><li>List of Holidays</li></a>
                            <a href="https://www.iitrpr.ac.in/academic/convocation" target="_blank"><li>Convocation</li></a>
                        </ul>
                    </div>

                    <div className="footer-section-content">
                        <h3 className="footer-heading">USEFUL LINKS</h3>
                        <ul>
                            <a href="https://www.iitrpr.ac.in/ranking" target="_blank"><li>Ranking</li></a>
                            <a href="https://www.iitrpr.ac.in/anti-ragging" target="_blank"><li>Anti-Ragging</li></a>
                            {/* <a href="https://bost-19.github.io/" target="_blank"><li>BOST</li></a> */}
                            <a href="https://www.iitrpr.ac.in/bost" target="_blank"><li>BOST</li></a>
                            <a href="https://www.iitrpr.ac.in/enactus/" target="_blank"><li>ENACTUS</li></a>
                            <a href="https://www.iitrpr.ac.in/indo-taiwan/" target="_blank"><li>Indo-Taiwan joint Reseach Centre</li></a>
                            <a href="https://sites.google.com/site/ropariprcell" target="_blank"><li>IPR Cell </li></a>
                            <a href="https://www.iitrpr.ac.in/rutag" target="_blank"><li>RuTAG</li></a>
                            <a href="https://www.iitrpr.ac.in/tbif/" target="_blank"><li>TBI</li></a>
                            <a href="https://www.iitrpr.ac.in/womensforum/" target="_blank"><li>Women's Forum</li></a>
                            <a href="https://www.iitrpr.ac.in/EOC" target="_blank"><li>Institute staff (SC/ST/OBC) Grievance Cell</li></a>
                            <a href="https://nad.digilocker.gov.in/" target="_blank"><li>Digi Locker</li></a>
                            <a href="https://iitrpr.samarth.ac.in/index.php/site/login" target="_blank"><li>Samarth Login </li></a>

                        </ul>
                    </div>

                    <div className="footer-section-content">
                        <h3 className="footer-heading">OTHER LINKS</h3>
                        <ul>
                            <a href="https://www.iitrpr.ac.in/helpdesk/" target="_blank"><li style={{ fontSize: "16px", color: "#c1ffd9", fontWeight: "bold" }}>HelpDesk</li></a>
                            <a href="https://www.iitrpr.ac.in/it" target="_blank"><li style={{ fontSize: "16px", color: "#c1ffd9", fontWeight: "bold" }}>IT Website</li></a>
                            <a href="https://www.iitrpr.ac.in/store-purchase/" target="_blank"><li>Store & Purchase</li></a>
                            <a href="https://www.iitrpr.ac.in/telephone-directory" target="_blank"><li>Institute Telephone Directory</li></a>
                            <a href="https://www.iitrpr.ac.in/iitrpr-gian-course" target="_blank"><li>GIAN Course at IIT Ropar</li></a>
                            <a href="https://www.iitrpr.ac.in/prajwalam-quarterly-newsletter-iit-ropar" target="_blank"><li>NewsLetter</li></a>
                            <a href="https://www.iitrpr.ac.in/right-information-act-0" target="_blank"><li>RTI</li></a>
                            <a href="https://www.iitrpr.ac.in/sites/default/files/Updated%20RR%20%26%20PP_2022.pdf" target="_blank"><li>Recruitment and Promotion Policy </li></a>
                            <a href="https://www.iitrpr.ac.in/tenders" target="_blank"><li>Tenders</li></a>
                            <a href="https://www.iitrpr.ac.in/%E0%A4%B9%E0%A4%BF%E0%A4%82%E0%A4%A6%E0%A5%80-%E0%A4%AA%E0%A5%8D%E0%A4%B0%E0%A4%95%E0%A5%8B%E0%A4%B7%E0%A5%8D%E0%A4%A0" target="_blank"><li>हिंदी प्रकोष्ठ</li></a>
                            <a href="https://www.iitrpr.ac.in/kshitij/" target="_blank"><li>क्षितिज-The Horizon</li></a>
                            <a href="https://www.pmindia.gov.in/hi/government_tr_rec/%E0%A4%AC%E0%A5%87%E0%A4%9F%E0%A5%80-%E0%A4%AC%E0%A4%9A%E0%A4%BE%E0%A4%93-%E0%A4%AC%E0%A5%87%E0%A4%9F%E0%A5%80-%E0%A4%AA%E0%A4%A2%E0%A4%BC%E0%A4%BE%E0%A4%93-%E0%A4%AC%E0%A4%BE%E0%A4%B2/" target="_blank"><li>Beti Bachao Beti Padhao</li></a>
                            <a href="https://studyinindia.gov.in/admission/registrations" target="_blank"><li>Study in India Portal</li></a>
                        </ul>
                    </div>

                    <div className="footer-section-content">
                        <h3 className="footer-heading">CONTACT DETAILS</h3>
                        <ul>
                            <a href="" target="_blank"><li>Indian Institute of Technology Ropar,</li></a>
                            <a href="" target="_blank"><li>Rupnagar,Punjab - 140001, India</li></a>
                        </ul>
                        <div className="social-icon">
                            <p>follow us-</p>
                            <div className="icons">
                                <a href="https://www.facebook.com/iitrpr" target="_blank"><img src={facebook} alt="" /></a>
                                <a href="https://x.com/iitrpr?mx=2" target="_blank"><img src={twitter} alt="" /></a>
                                <a href="https://www.instagram.com/iitrpr_iitrpr/#" target="_blank"><img src={instagram} alt="" /></a>
                            </div>
                        </div>

                        <div className="location">
                            <img src={location} alt="" />
                            <a href="https://www.google.co.in/maps/dir/Rupnagar+Old+Bus+Stand,+Chhota+Khera,+Basant+Nagar,+Rupnagar,+Punjab/Indian+institute+of+Technology+Ropar,+Bara+Phool,+Punjab/@30.9620135,76.4693569,13z/am=t/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x3905543310e70e63:0x63397d7938035537!2m2!1d76.5338308!2d30.9669692!1m5!1m1!1s0x390554d4ffffffff:0xb81f1e2708c91100!2m2!1d76.4731998!2d30.9709183?shorturl=1" target="_blank">How to Reach IIT Ropar</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="lower-line-copyright">
                <div className="copy-data">
                    <div className="cont-copy">Copyright © 2025, IIT Ropar</div>
                    <div className="cont-copy">Designed by IT Services</div>
                </div>
            </div>
                    {/* <div className="testingbtn"><Link to="/newsentry">login</Link></div> */}
        </div>
    )
}