import React, { useState, useEffect, useRef } from "react";
import "../Css/Home.css";
import Navbar from "../Utils/Navbar";

import slid1 from "../Assets/slider-one.png";
import slid2 from "../Assets/slider-two.png";
import slid3 from "../Assets/slider-three.png";
import piwot from "../Assets/Banner-piwot.jpg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import rnd from "../Assets/rnd.png";
import listlogo from "../Assets/newwindow.png";
import admission from "../Assets/admission.png";
import placement from "../Assets/placement.png";
import studentact from "../Assets/studentactivity.png";
import globalcon from "../Assets/globalconnect.png";
import certifypro from "../Assets/certificate.png";
import about from "../Assets/aboutiit.png";
import director from "../Assets/director.png";
import alumni from "../Assets/alumni.png";
import newnote from "../Assets/newicon.png";
import Footer from "../Utils/Footer";

const data = [
  {
    img: slid1,
  },
  {
    img: slid2,
    // img: slid2,
  },
  {
    img: slid3,
  },
];

export default function Home() {


  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setLoading(false); // After 2 seconds, stop loading and show main content
  //   }, 10000); // Change this value to adjust loading time

  //   return () => clearTimeout(timer); // Cleanup on component unmount
  // }, []);
   

  // const handleSkipLoading = () => {
  //   setLoading(false);  // Remove loading screen on button click
  // };




  const words = [
    
    
    { english: "Adverse report", hindi: "प्रतिकूल रिपोर्ट" },
    { english: "Adverse entry", hindi: "प्रतिकूल प्रविष्टि" },
    { english: "Advertise", hindi: "विज्ञापन देना" },
    { english: "Advertised sale", hindi: "विज्ञापित बिक्री" },
    { english: "Advertised tender", hindi: "विज्ञापित निविदा" },
    { english: "Advertisement", hindi: "विज्ञापन" },
    { english: "Advertiser", hindi: "विज्ञापन दाता" },
    { english: "Advertising agency", hindi: "विज्ञापन एजेंसी" },
    { english: "Advertising rate", hindi: "विज्ञापन दर" },
    { english: "Advertising space", hindi: "विज्ञापन स्थान" },
    { english: "Advertorial", hindi: "विज्ञापनिका" },
    { english: "Advice", hindi: "सलाह" },
    { english: "Advice Note", hindi: "सूचना पत्र" },
    { english: "Advice of payment", hindi: "भुगतान सूचना" },
    { english: "Advisory", hindi: "(वि.) सलाहकार" },
    { english: "Advisory board", hindi: "सलाहकार मंडल" },
    { english: "Advisory committee", hindi: "सलाहकार समिति" },
    { english: "Advisory council", hindi: "सलाहकार परिषद्" },
    { english: "Advisory panel", hindi: "सलाहकार नामिका" },
    { english: "Affairs", hindi: "मामले" },
    { english: "Affidavit", hindi: "शपथपत्र" },
    { english: "Affiliate", hindi: "संबद्ध करना" },
    { english: "Affiliated college", hindi: "संबद्ध महाविद्यालय" },
    { english: "Advance pay", hindi: "अग्रिम वेतन" },
    { english: "Advance payment", hindi: "अग्रिम अदायकी, अग्रिम भुगतान" },
    { english: "Advancement", hindi: "उन्नति" },
    { english: "Advantage", hindi: "अनुकूल स्थिति" },
    { english: "Adverse order", hindi: "प्रतिकूल आदेश" },
    { english: "Affiliating university", hindi: "संबंधक विश्वविद्यालय" },
    { english: "Affiliation", hindi: "संबंधन" },
    { english: "Aforesaid", hindi: "पूर्वकथित" },
    { english: "Agenda setting", hindi: "कार्यसूची निर्धारण" },
    { english: "Aid coordination", hindi: "सहायता समन्वय" },
    { english: "Air field", hindi: "हवाई क्षेत्र" },
    { english: "Air mail", hindi: "हवाई डाक" },
    { english: "Allocate", hindi: "नियत करना" },
    { english: "Allocation", hindi: "नियतन" },
    { english: "Allocation of business", hindi: "कार्य नियतन" },
    { english: "Allocation of function", hindi: "प्रकार्य नियतन" },
    { english: "Allocation of resources", hindi: "संसाधन नियतन" },
    { english: "Allocation of work", hindi: "कार्य नियतन" },
    { english: "Allocation rate", hindi: "नियतन दर" },
    { english: "Allotment", hindi: "आबंटन" },
    { english: "Allotment letter", hindi: "आबंटन-पत्र" },
    { english: "Adverse ACR", hindi: "प्रतिकूल एसीआर" },
    { english: "Adverse", hindi: "प्रतिकूल" },
    { english: "Advance increment", hindi: "अग्रिम वृद्धि (वेतन)" },
    
    { english: "Adverse impact", hindi: "प्रतिकूल प्रभाव" },
    { english: "Adverse party", hindi: "प्रतिपक्षी" },
    { english: "Adverse remarks", hindi: "प्रतिकूल अभ्युक्ति" },
  ];

  const [currentWord, setCurrentWord] = useState(words[0]);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    // Check if there is a saved word and last update time in localStorage
    const savedCounter = parseInt(localStorage.getItem("counter"), 10);
    const lastUpdate = localStorage.getItem("lastUpdate");

    if (!isNaN(savedCounter) && lastUpdate) {
      const lastDate = new Date(parseInt(lastUpdate, 10));
      const now = new Date();

      // If the date has changed, update the word
      if (now.getDate() !== lastDate.getDate()) {
        const newCounter = (savedCounter + 1) % words.length;
        setCounter(newCounter);
        setCurrentWord(words[newCounter]);
        localStorage.setItem("counter", newCounter.toString());
        localStorage.setItem("lastUpdate", now.getTime().toString());
      } else {
        // Restore the current word
        setCounter(savedCounter);
        setCurrentWord(words[savedCounter]);
      }
    } else {
      // Initialize localStorage if not already set
      localStorage.setItem("counter", "0");
      localStorage.setItem("lastUpdate", Date.now().toString());
      setCounter(0);
      setCurrentWord(words[0]);
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();

      // Check if it's 12:00 AM
      if (now.getHours() === 0 && now.getMinutes() === 0) {
        const newCounter = (counter + 1) % words.length;
        setCounter(newCounter);
        setCurrentWord(words[newCounter]);
        localStorage.setItem("counter", newCounter.toString());
        localStorage.setItem("lastUpdate", now.getTime().toString());
      }
    }, 60000); // Check every minute

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [counter]);



  // ----------BUTTON TO CHANGE DIV--------
  const [showtab, setshowtab] = useState(1);
  const handletab = (e) => {
    setshowtab(e);
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-prev-arrow`}
        style={{
          ...style,
          left: "3px",
          top: "200px",
          zIndex: 1,
          // background: 'rgba(0, 0, 0, 0.5)', // Optional: make arrow background dark
          borderRadius: "50%",
          padding: "10px",
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <span style={{ color: "white", fontSize: "18px" }}></span>
      </div>
    );
  };

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-next-arrow`}
        style={{
          ...style,
          right: "24px",
          top: "200px",
          zIndex: 1,
          // background: 'rgba(0, 0, 0, 0.5)', // Optional: make arrow background dark
          borderRadius: "50%",
          padding: "10px",
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <span style={{ color: "white", fontSize: "18px" }}></span>
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6500,
    pauseOnHover: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  // State to control which tab is active
  const [activeTab, setActiveTab] = useState("facebook");

  // Load Facebook SDK
  useEffect(() => {
    if (activeTab === "facebook") {
      const loadFacebookSDK = () => {
        if (document.getElementById("facebook-jssdk")) return;
        const script = document.createElement("script");
        script.id = "facebook-jssdk";
        script.src = "//connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v2.9";
        document.body.appendChild(script);
      };

      loadFacebookSDK();
    }
  }, [activeTab]);

  return (
    <>
      {/* Loading Screen */}
      {/* {loading && (
        <div className="loading-screen">
          <img src={piwot} alt="Loading..." />
          <button className="skip-button" onClick={handleSkipLoading}>
            Skip
          </button>
        </div>
      )} */}
       
          <Navbar />
          {/* <div className={`main-container ${loading ? 'blurred' : ''}`}> */}
          <div className="main-container">
            {/* ---slider---- */}
            <div className="banner-h">
              <Slider {...settings}>
                {data.map((d, index) => (
                  <div className="background-h" key={index}>
                    <img src={d.img} alt={`Slide ${index + 1}`} />
                  </div>
                ))}
              </Slider>
            </div>
            {/* ---slider-end--- */}

            {/* ----------middle-section------------------- */}

            <div className="middle-section">
              {/* ----------middle-left----------- */}
              <div className="middle-left">
                <div className="middle-left-content">
                  <div className="middle-content">
                    <div className="middle-content-items">
                      <p className="inner-p">
                        <a href="https://www.iitrpr.ac.in/RnD/" target="_blank">
                          Visit R&D Page
                        </a>
                      </p>
                      <div className="middle-hov-items">
                        <img src={rnd} alt="img" />
                        <p className="outter-p">R&D</p>
                      </div>
                    </div>
                  </div>

                  <div className="middle-content">
                    <div className="middle-content-items">
                      <p className="inner-p">
                        <a
                          href="https://www.youtube.com/embed/XXXhTiZzPz4?rel=0embed&"
                          target="_blank"
                        >
                          Campus View
                        </a>
                      </p>
                      <p className="inner-p">
                        <a
                          href="https://www.iitrpr.ac.in/admissions"
                          target="_blank"
                        >
                          Visit the Admission Page
                        </a>
                      </p>
                      <div className="middle-hov-items">
                        <img src={admission} alt="img" />
                        <p className="outter-p">Admissions</p>
                      </div>
                    </div>
                  </div>

                  <div className="middle-content">
                    <div className="middle-content-items">
                      <p className="inner-p">
                        IIT Ropar, through their Career Development and
                        Corporate Relations Centre (CDCRC) aims at Giving its
                        students a career orientation in terms of options they
                        have, namely careers in the industry, academia, R&D,
                        entrepreneurship, public service etc.
                        <a
                          href="https://www.iitrpr.ac.in/cdpc/"
                          target="_blank"
                          style={{ color: "red" }}
                        >
                          {" "}
                          Read More
                        </a>
                      </p>
                      <div className="middle-hov-items">
                        <img src={placement} alt="img" />
                        <p className="outter-p">Placements</p>
                      </div>
                    </div>
                  </div>

                  <div className="middle-content">
                    <div className="middle-content-items">
                      <p className="inner-p">
                        <a
                          href="https://www.iitrpr.ac.in/student-affairs/events.php?q=upcoming"
                          target="_blank"
                        >
                          Upcoming Activity
                        </a>
                      </p>
                      <p className="inner-p">
                        <a
                          href="https://www.iitrpr.ac.in/student-affairs/events.php?q=past"
                          target="_blank"
                        >
                          Past Activity
                        </a>
                      </p>
                      <div className="middle-hov-items">
                        <img src={studentact} alt="img" />
                        <p className="outter-p">Student Activities</p>
                      </div>
                    </div>
                  </div>

                  <div className="middle-content">
                    <div className="middle-content-items">
                      <p className="inner-p">
                        OIR @ IIT Ropar is also seen as a gateway to connect to
                        our growing and vibrant alumni, so as to bring their
                        corporate and industry expertise back on campus.
                        <a
                          href="https://www.iitrpr.ac.in/oir/"
                          style={{ color: "red" }}
                          target="_blank"
                        >
                          {" "}
                          Read More
                        </a>
                      </p>
                      <div className="middle-hov-items">
                        <img src={globalcon} alt="img" />
                        <p className="outter-p">Global Connect</p>
                      </div>
                    </div>
                  </div>

                  <div className="middle-content">
                    <div className="middle-content-items">
                      <p className="inner-p">
                        IIT Ropar & CII announces admission in 3rd batch of
                        Online Post Graduate Professional Development Program in
                        Corrosion Management and Technology (Deadline: October
                        10, 2024)
                        <a
                          href="https://www.iitrpr.ac.in/sites/default/files/Third%20Batch%20of%20IIT-CII%20on%20Corrosion%20Management%20and%20Technology.pdf"
                          target="_blank"
                          style={{ color: "red" }}
                        >
                          {" "}
                          Click here for detailed Adv..
                        </a>
                      </p>
                      <div className="middle-hov-items">
                        <img src={certifypro} alt="img" />
                        <p className="outter-p">Certificate Programs</p>
                      </div>
                    </div>
                  </div>

                  <div className="middle-content">
                    <div className="middle-content-items">
                      <p className="inner-p">
                        <a
                          href="https://www.iitrpr.ac.in/about-iit-ropar"
                          target="_blank"
                        >
                          About IIT Ropar
                        </a>
                      </p>
                      <p className="inner-p">
                        <a
                          href="https://www.iitrpr.ac.in/sites/default/files/IIT%20Ropar%20-%20social%20media-2.pdf"
                          target="_blank"
                        >
                          Fellowships for JEE
                        </a>
                      </p>
                      <div className="middle-hov-items">
                        <img src={about} alt="img" />
                        <p className="outter-p">All About IIT Ropar</p>
                      </div>
                    </div>
                  </div>

                  <div className="middle-content">
                    <div className="middle-content-items">
                      <p className="inner-p">
                        <a
                          href="https://www.iitrpr.ac.in/directors-desk"
                          target="_blank"
                        >
                          Visit Director's Desk
                        </a>
                      </p>
                      <div className="middle-hov-items">
                        <img src={director} alt="img" />
                        <p className="outter-p">Director's Desk</p>
                      </div>
                    </div>
                  </div>

                  <div className="middle-content">
                    <div className="middle-content-items">
                      <p className="inner-p">
                        <a
                          href="https://alumniconnect.iitrpr.ac.in/"
                          target="_blank"
                        >
                          Visit Alumni Website
                        </a>
                      </p>
                      <div className="middle-hov-items">
                        <img src={alumni} alt="img" />
                        <p className="outter-p">IIT Ropar Alumni</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="middle-left-content-down">
                  <div className="left-mid-cont-down">
                    <p style={{ marginBottom: "4px" }}>
                      IIT ROPAR GREEN CAMPUS
                    </p>
                    <iframe
                      src="https://www.youtube.com/embed/XXXhTiZzPz4?rel=0embed&amp;showinfo=0"
                      frameborder="0"
                      width="298"
                      height="150"
                      allowfullscreen
                    ></iframe>
                  </div>

                  <div className="right-mid-cont-down">
                    <div className="uper-mid-cont-down">
                      <p>TESTIMONALS</p>
                      <a
                        href="https://www.iitrpr.ac.in/alumni-testimonials"
                        target="_blank"
                      >
                        <button>Read more</button>
                      </a>
                    </div>
                    <div className="lower-mid-cont-down">
                      <p>
                        Academically, the concepts I learnt about mechanical
                        engineering are being put to good use in my current job
                        profile. The ideal faculty student ratio at the
                        institute not only helps in better understanding but
                        creates an informal bond with the faculty. Right from
                        doubts in courses to advice on what to do in life, the
                        faculty has always been there to help.
                      </p>
                      <span style={{ marginTop: "5px", display: "block" }}>
                        Tarun Mittal
                      </span>
                      <span style={{ display: "block" }}>
                        Junior Manager, CET
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* -----------middle-right-------------- */}
              <div className="middle-right">
                <div className="m-r-upper">
                  <div className="uper-row-btn">
                    <button
                      onClick={() => handletab(1)}
                      className={showtab == 1 ? "active" : "inactive"}
                    >
                      News & Announcements
                    </button>
                    <button
                      onClick={() => handletab(2)}
                      className={showtab == 2 ? "active" : "inactive"}
                    >
                      Events
                    </button>
                  </div>

                  <div
                    className={
                      showtab == 1
                        ? "row-content-news"
                        : "row-content-news hidden"
                    }
                  >
                    <ul className="row-list">
                    {/* <a
                        href="https://www.iitrpr.ac.in/international-piwot"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                          Announcing the PIWOT Satellite Conference 2025 at IIT
                          Ropar on 23.01.2025!{" "}
                          <img className="new-note" src={newnote} alt="" />
                        </li>
                      </a> */}

                      {/* <a
                        href="https://www.iitrpr.ac.in/sites/default/files/Science%20Day%202025-1.jpg"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                        Science Day Lecture Series with Detailed Programme
                          <img className="new-note" src={newnote} alt="" />{" "}
                        </li>
                      </a> */}

                      <a
                        href="https://www.iitrpr.ac.in/mtech"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                        
                        Opening of call of application for M.Tech admission 1st semester of AY 2025-26
                          <img className="new-note" src={newnote} alt="" />{" "}
                        </li>
                      </a>

                      <a
                        href="https://www.iitrpr.ac.in/phd-categories"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                        
                        Opening of call of application for PhD admission 1st semester of AY 2025-26
                          <img className="new-note" src={newnote} alt="" />{" "}
                        </li>
                      </a>

                      <a
                        href="https://www.iitrpr.ac.in/staff-positions"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                        
                        Notice regarding written or final result of various non teaching posts advertised against Advt. No. 01/2024
                          <img className="new-note" src={newnote} alt="" />{" "}
                        </li>
                      </a>

                      <a
                        href="https://www.iitrpr.ac.in/staff-positions"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                        
                        Notice regarding Written Test scheduled on 07.04.2025 for the post of Technical Officer (CRF) advertised vide Advertisement No. 01/2024
                          <img className="new-note" src={newnote} alt="" />{" "}
                        </li>
                      </a>

                      <a
                        href="https://www.iitrpr.ac.in/staff-positions"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                        
                        List of Shortlisted and Not-Shortlisted Candidates for the post of Technical Officer (IT) against Advertisement. No. 01/2024
                          <img className="new-note" src={newnote} alt="" />{" "}
                        </li>
                      </a>

                      <a
                        href="https://www.iitrpr.ac.in/summer-internship-programme-result"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                        
                        List of selected candidates under Summer Internship 2025
                          <img className="new-note" src={newnote} alt="" />{" "}
                        </li>
                      </a>

                      <a
                        href="https://www.iitrpr.ac.in/international_students/howtoapply.html"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                        
                        Application Form  for IIT Ropar Summer Research Internship Program 2025 for International Students Studying in India.
                          <img className="new-note" src={newnote} alt="" />{" "}
                        </li>
                      </a>

                      <a
                        href="https://www.iitrpr.ac.in/staff-positions"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                        
                        Notice regarding Written Test scheduled on 04.04.2025 for the post of Sports Officer advertised vide Advertisement No. 01/2024.
                          <img className="new-note" src={newnote} alt="" />{" "}
                        </li>
                      </a>
                      <a
                        href="https://www.iitrpr.ac.in/staff-positions"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                        
                        Notice regarding Written Test & Trade test/Interview scheduled on 02.04.2025 & 03.04.2025 respectively for the post of Deputy Librarian advertised vide Advertisement No. 01/2024.
                          <img className="new-note" src={newnote} alt="" />{" "}
                        </li>
                      </a>

                      <a
                        href="https://www.iitrpr.ac.in/staff-positions"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                        
                        Notice regarding Written Test scheduled on 24.03.2025 for the post of Junior Technical Superintendent (Electrical Engineering) advertised vide Advertisement No. 01/2024
                          <img className="new-note" src={newnote} alt="" />{" "}
                        </li>
                      </a>

                      {/* <a
                        href="https://www.iitrpr.ac.in/phycon"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                        
                        The National Physics Conference (PHYCON-25), 21-22 March 2025.
                          <img className="new-note" src={newnote} alt="" />{" "}
                        </li>
                      </a> */}

                      {/* <a
                        href="https://www.iitrpr.ac.in/staff-positions"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                        
                        Results of written test for the position of Junior Lab Assistant (Electrical Engineering), Junior Lab Assistant (Physics), Junior Lab Assistant (Metallurgical & Materials Engineering) against advt. no. 01/2024.
                        
                          <img className="new-note" src={newnote} alt="" />{" "}
                        </li>
                      </a> */}

                      {/* <a
                        href="https://www.iitrpr.ac.in/staff-positions"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                        Notice regarding Written Test scheduled on 05.03.2025 for the posts of Junior Lab Ass. (HSS) & Junior Lab Ass. (CRF) advertised vide Advt. No. 01/2024
                          <img className="new-note" src={newnote} alt="" />{" "}
                        </li>
                      </a> */}

                      {/* <a
                        href="https://www.iitrpr.ac.in/summer-internship-0"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                        Call of application for Summer Internship programme from May 15 to July 15, 2025.
                          <img className="new-note" src={newnote} alt="" />{" "}
                        </li>
                      </a> */}


                      <a
                        href="https://www.iitrpr.ac.in/staff-positions"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                        Final results for the position of Junior Engineer (Civil), Junior Hindi Translation Officer against advt. no. 01/2024.
                        {/* Indo-US Conference cum Workshop 2025: From First Line to Final Strike – Innate and Adaptive Immunity, scheduled for February 16-17, 2025, at IIT Ropar. */}
                          <img className="new-note" src={newnote} alt="" />{" "}
                        </li>
                      </a>  



                      {/* <a
                        href="https://www.iitrpr.ac.in/faculty-positions-iitrpr"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                        Faculty advt. No. IITRPR/FACRECT/01/2025 for recruitment to the post of Assistant Professor in the AIDE.
                          <img className="new-note" src={newnote} alt="" />{" "}
                        </li>
                      </a> */}
                      
                      
                       {/* <a
                        href="https://www.iitrpr.ac.in/staff-positions"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                        Results for the position of Junior Lab Assistant (Mathematics), Junior Lab Assistant (Civil Engineering), Junior Lab Assistant (CSE), Junior Lab Assistant (Chemical Engineering) against advt. no. 01/2024.
                      
                          <img className="new-note" src={newnote} alt="" />{" "}
                        </li>
                      </a> */}

                        <a
                        href="https://www.iitrpr.ac.in/staff-positions"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                        List of Shortlisted, Provisionally Shortlisted and Not-Shortlisted Candidates for the post of Public Relations Officer against Advt. No. IITRPR/NT/04/2024.
                        {/* Indo-US Conference cum Workshop 2025: From First Line to Final Strike – Innate and Adaptive Immunity, scheduled for February 16-17, 2025, at IIT Ropar. */}
                          <img className="new-note" src={newnote} alt="" />{" "}
                        </li>
                      </a> 

                        <a
                        href="https://www.iitrpr.ac.in/staff-positions"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                        Results for the position of Junior Lab Assistant (Biomedical Engineering), Junior Lab Assistant (Central Workshop), Junior Assistant, Junior Assistant Accounts against advt. no. 01/2024.
                        {/* Indo-US Conference cum Workshop 2025: From First Line to Final Strike – Innate and Adaptive Immunity, scheduled for February 16-17, 2025, at IIT Ropar. */}
                          <img className="new-note" src={newnote} alt="" />{" "}
                        </li>
                      </a> 


                      {/* <a
                        href="https://www.iitrpr.ac.in/sites/default/files/2025.2.6_Extended%20date_Workshop%20Event%20Flyer.pdf"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                        Registration deadline for the Indo-US Conference cum Workshop 2025 has been extended to February 10, 2025.
                          <img className="new-note" src={newnote} alt="" />{" "}
                        </li>
                      </a> */}

                      <a
                        href="https://www.iitrpr.ac.in/jobs/faculty-positions"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                        Notice regarding cancellation of advertisement for the Visiting Faculty position in the Department of HSS.
                          <img className="new-note" src={newnote} alt="" />{" "}
                        </li>
                      </a> 


                      {/* <a
                        href="https://sites.google.com/iitrpr.ac.in/icsl-2025/home?authuser=0"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                        International Conference on Statistical Learning, scheduled for March 6-8, 2025, at IIT Ropar.
                          <img className="new-note" src={newnote} alt="" />{" "}
                        </li>
                      </a> */}


                      {/* <a
                        href="https://www.iitrpr.ac.in/staff-positions"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                        Result of written test held on 17.12.2024 for the recruitment to the post of Senior Library Information Assistant,  Library Information Assistant against advt. no. 01/2024.
                          <img className="new-note" src={newnote} alt="" />{" "}
                        </li>
                      </a> */}


                      {/* <a
                        href="https://www.iitrpr.ac.in/staff-positions"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                          Notice regarding Written Test scheduled on 29.01.2025
                          for the post of Junior Lab Assistant (Chemistry)
                          advertised vide Advertisement No. 01/2024.
                          <img className="new-note" src={newnote} alt="" />{" "}
                        </li>
                      </a> */}

                      {/* <a
                        href="https://www.iitrpr.ac.in/staff-positions"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                          Result of written test held on 13.11.2024 for the
                          recruitment to the post of Junior Superintendent
                          against advt. no. 01/2024
                          <img className="new-note" src={newnote} alt="" />{" "}
                        </li>
                      </a> */}

                      {/* <a href="https://www.iitrpr.ac.in/ms-datascience" target="_blank" className="list-content">
                  <div className="list-logo">
                    <img src={listlogo} alt="" />
                  </div>
                  <li>Admission under MS in Data Science programme for IIT Madras BS students January 2025<img className='new-note' src={newnote} alt="" /></li>
                </a> */}

                
                      {/* <a
                        href="https://www.iitrpr.ac.in/gian-course"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                          GIAN course "Mechanics of fluids in human physiology:
                          establishes approaches and grand questions".
                          <img className="new-note" src={newnote} alt="" />
                        </li>
                      </a> */}

                      <a
                        href="https://www.nielit.gov.in/sites/default/files/Chandigarh/AIML-IITRopar%20Jan25.pdf"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                          Six Months Training in Artificial Intelligence with
                          Machine Learning
                          <img className="new-note" src={newnote} alt="" />
                        </li>
                      </a>

                      {/* <a href="https://www.iitrpr.ac.in/staff-positions" target="_blank" className="list-content">
                  <div className="list-logo">
                    <img src={listlogo} alt="" />
                  </div>
                  <li>List of Shortlisted, Provisionally Shortlisted and Not-Shortlisted Candidates for the post of Assistant Executive Engineer (Civil) against Advertisement. No. 01/2024.<img className='new-note' src={newnote} alt="" /></li>
                </a> */}

                      {/* <a
                        href="https://www.iitrpr.ac.in/academic/phd"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                          PhD Joint Degree Programme (IIT Mandi - IIT Ropar)
                          application is live now for Even semester(AY:
                          2024-2025)
                          <img className="new-note" src={newnote} alt="" />
                        </li>
                      </a> */}

                      <a
                        href="https://www.iitrpr.ac.in/sites/default/files/Workshop_Green%20Computing.png"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                          Green Computing: Challenges, Opportunities & Recent
                          Innovations
                          <img className="new-note" src={newnote} alt="" />
                        </li>
                      </a>

                      <a
                        href="https://www.iitrpr.ac.in/iit-ropar-ai"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                          Minor in Artificial Intelligence (AI) program at IIT
                          Ropar
                          <img className="new-note" src={newnote} alt="" />
                        </li>
                      </a>

                      <a
                        href="https://www.iitrpr.ac.in/sites/default/files/AIML_jun24.png"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                          Six Months Training in Artificial Intelligence with
                          Machine Learning. For more details:{" "}
                          <a
                            href="https://www.iitrpr.ac.in/sites/default/files/AIML-IITRopar%20Course%20Brochure.pdf"
                            target="_blank"
                          ></a>{" "}
                          (CLICK HERE)
                          <img className="new-note" src={newnote} alt="" />
                        </li>
                      </a>

                      <a
                        href="https://www.iitrpr.ac.in/jobs/faculty-positions"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                          Special Recruitment drive for the post of Associate
                          Professor and Assistant Professor in SC/ST/OBC/EWS
                          categories
                        </li>
                      </a>

                      <a
                        href="https://www.iitrpr.ac.in/positions-awadh"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                          Various Positions at DST iHub - AWaDH for
                          Cyber-Physical Systems.
                        </li>
                      </a>
                    </ul>
                  </div>

                  <div
                    className={
                      showtab == 2
                        ? "row-content-events"
                        : "row-content-events hidden"
                    }
                  >
                    <ul className="row-list">
                      {/* <a
                        href="https://www.iitrpr.ac.in/international-piwot"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                          Announcing the PIWOT Satellite Conference 2025 at IIT
                          Ropar on 23.01.2025!{" "}
                          <img className="new-note" src={newnote} alt="" />
                        </li>
                      </a> */}

                      <a
                        href="https://www.iitrpr.ac.in/panel-discussion"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                          Panel discussion on graphene applications in aerospace
                          & defense with Prof. Rajeev Ahuja, and Nobel Laureate
                          Prof. Andre Geim.
                          {/* <img className="new-note" src={newnote} alt="" /> */}
                        </li>
                      </a>

                      <a
                        href="https://www.iitrpr.ac.in/Independence-Day"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                          78th Independence Day Celebration at IIT Ropar.
                          {/* <img className="new-note" src={newnote} alt="" /> */}
                        </li>
                      </a>

                      <a
                        href="https://www.iitrpr.ac.in/academic/convocation"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                          The 13th Convocation of IIT Ropar is scheduled to be
                          held on 15th July 2024
                          {/* <img className="new-note" src={newnote} alt="" /> */}
                        </li>
                      </a>

                      <a
                        href="https://www.iitrpr.ac.in/idy"
                        target="_blank"
                        className="list-content"
                      >
                        <div className="list-logo">
                          <img src={listlogo} alt="" />
                        </div>
                        <li>
                          IIT Ropar 10th international day of yoga on 21 June
                          2024
                          {/* <img className="new-note" src={newnote} alt="" /> */}
                        </li>
                      </a>
                    </ul>
                  </div>
                </div>

                <div className="middle-donate-btn">
                  <a
                    href="http://www.cga.iitrpr.ac.in/?source=opt1"
                    target="_blank"
                  >
                    <button className="donate-m">Donate to IIT Ropar</button>
                  </a>
                </div>

                <div className="social-upt">
                  <p>SOCIAL UPDATES</p>
                  <div className="social-upt-btn">
                    <button
                      className={activeTab === "facebook" ? "active" : ""}
                      onClick={() => setActiveTab("facebook")}
                    >
                      FACEBOOK
                    </button>
                  </div>
                  <div className="social-upt-content">
                    {activeTab === "facebook" && (
                      <div className="fb-feed">
                        <div
                          className="fb-page"
                          data-href="https://www.facebook.com/iitrpr"
                          data-tabs="timeline"
                          data-width="320"
                          data-height="310"
                          data-small-header="true"
                          data-adapt-container-width="true"
                          data-hide-cover="false"
                          data-show-facepile="false"
                        >
                          <blockquote
                            className="fb-xfbml-parse-ignore"
                            cite="https://www.facebook.com/iitrpr"
                          >
                            <a href="https://www.facebook.com/iitrpr">
                              Indian Institute of Technology Ropar
                            </a>
                          </blockquote>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="hindi-word">
                  <div className="hindi-content">
                    <div className="hindi-head">
                      <p>आज का शब्द</p>
                      <p>(प्रशासनिक शब्दावली)</p>
                    </div>

                    <p className="p">
                      {" "}
                      {currentWord.hindi} - {currentWord.english}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </>
      
    
  );
}
